<template>
  <div class="firstReward">
    <div class="top">
      <!-- <div class="logo">
        <img src="../../assets/logo-h5.png" alt="" class="logo_fanhong">
        翻红馆
        <span>x</span>
        <img src="../../assets/jd.png" alt="" class="logo_jd">
      </div>
      <div class="subTitle">翻红馆x京东抖音店铺拉新</div>
      <div class="title">首购奖励</div>
      <div class="remark">*此活动仅限抖音达人参加</div> -->
    </div>
    <div class="con">
      <div class="tab">
        <div :class="{'tab_item':true, 'active':active==0}" @click="changeTab(0)">
          首页
        </div>
        <div class="tab_item" :class="{'tab_item':true, 'active':active==1}" @click="changeTab(1)">
          商品列表
        </div>
        <div class="tab_item" :class="{'tab_item':true, 'active':active==2}" @click="changeTab(2)">
          主播收益榜
        </div>
        <span></span>
      </div>
      <div class="content">
        <div class="index" v-if="active==0">
          <div class="intro">
            <div class="title">
              <h3>活动介绍</h3>
              <span>翻红馆x京东首购拉新</span>
            </div>
            <div class="intro_con">
              <p>1.应京东官方要求，开通京东抖音旗舰店福利品带货权限的抖音账号需通过翻红馆邀约链接扫码授权成功方可参加。</p>
              <p>2.活动期间，在抖音平台售卖本专区拉新福利商品，用户下单并实际支付1-1.5元的为“新人订单”，订单收益按实际佣金率计算。</p>
              <span @click="changeDetailShow(true)">活动详情>></span>
            </div>
          </div>
          <div>
            <div class="title">
              <h3>提交信息</h3>
              <span>输入抖音昵称、UID和手机号</span>
            </div>
            <a-form :form="form" class="form" @submit="handleSubmit">
              <a-form-item>
                <a-input class="first-child" autocomplete="off" v-decorator="[
                  'nickname',
                  {
                    rules: [{ required: false, message: '请输入抖音昵称!' }],
                  },
                ]" placeholder="输入抖音昵称">
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input class="first-child" autocomplete="off" v-decorator="[
                  'account_number',
                  {
                    rules: [{ required: false, message: '请输入抖音UID!' }],
                  },
                ]" placeholder="输入抖音UID">
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input class="first-child" autocomplete="off" v-decorator="[
                  'mobile',
                  {
                    rules: [{ required: false, message: '请输入手机号!' }],
                  },
                ]" placeholder="输入手机号">
                </a-input>
              </a-form-item>
              <a-form-item class="button">
                <a-button type="primary" html-type="submit" class="login-form-button">
                  提交
                </a-button>
              </a-form-item>
              <!-- <router-link tag="a" to="/register" class="gotoLogin"
          >还没有账号，马上注册</router-link
        > -->
            </a-form>
          </div>
          <div class="empower">
            <div class="title">
              <h3>获取带货商品品牌授权</h3>
              <span>授权后才能带货获得佣金哦！</span>
            </div>
            <div class="empower_con">
              <!-- <div class="empower_item">
                <img src="../../assets/logo-h5.png" alt="">
                <div>授权</div>
              </div>
              <div class="empower_item success">
                <img src="../../assets/logo-h5.png" alt="">
                <div>授权</div>
              </div> -->
              <div class="empower_item" v-for="(item,index) in empower" :key="index">
                <img :src="item.img?item.img:require('../../assets/logo-h5.png')" alt="">
                <a :href="item.href">授权</a>
              </div>
              <div v-if="empower.length%3==2" class="other"></div>
              <div v-if="empower.length%3==1" class="other"></div>
              <div v-if="empower.length%3==1" class="other"></div>
            </div>
          </div>
        </div>
        <div class="goods" v-if="active==1">
          <div class="goods_item" v-for="item in goods" :key="item.key">
            <img :src="item.img?item.img:require('../../assets/logo-h5.png')" alt="">
            <div class="goods_con">
              <div class="goods_title">
                <span>抖</span>{{item.name}}
              </div>
              <div class="goods_percent">佣金比例：{{item.percent}}%</div>
              <div class="goods_price">
                <b>新人价：¥{{item.price}}</b>
                /
                原价：￥<span>{{item.oldPrice}}</span>
              </div>
            </div>
            <span class="copy" @click="copy" slot="content" :data-clipboard-text="item.url">复制链接</span>
          </div>
        </div>
        <div class="ranking" v-if="active==2">
          <div class="ranking_item" v-for="(item,index) in list" :key="index">
            <div class="ranking_con">
              <span v-if="index<9">{{"0"+""+(index+1)}}</span>
              <span v-else>{{index+1}}</span>
              <img :src="item.usesInfo.avatar" alt="">
              <p>
                <span>{{item.usesInfo.nickname}}</span><br>
                <!-- <label v-for="(value,i) in item.platformInfo[0].anchor_type_name"
                  :key="i">{{i>0?"/":''}}{{value}}</label> -->
                  <label> 带货总数：{{item.order_num_total}}</label>
              </p>
            </div>
            <span class="ranking_account">¥{{item.fee}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detail" v-if="detailShow">
      <div class="detail_con">
        <div class="title">活动详情</div>
        <div class="all">
          <p>1.应京东官方要求，开通京东抖音旗舰店福利品带货权限的抖音账号需通过翻红馆邀约链接扫码授权成功方可参加</p>
          <p>2.活动期间，在抖音平台售卖本专区拉新福利商品，用户下单并实际支付1-1.5元的为“新人订单”，订单收益按实际佣金率计算</p>
          <p>3.售卖前须确保自己的抖音账号已扫码授权并”审核通过“，方可将福利品挂到抖音橱窗小黄车，否则无法获得收益结算</p>
          <p>4.精选联盟里可查看自己的拉新福利品出单笔数，佣金收益结算返回到翻红馆里提现即可，结算时间每个月25日</p>
          <p>5.今日新人订单数有5-10%误差，实际以页面显示的总收入为准，如果订单出现未妥投成功及违规下单等情况，则该订单无收益</p>
          <p>6.本次活动翻红馆免收内容服务费，仅收取0.1元/单作为翻红馆给京东开发票的税费支出，其余佣金全部结算给达人</p>
          <p>7.严禁冒用京东名义进行虚假宣传，禁止任何形式的免单宣传，一经发现取消活动资格。</p>
          <p>8.本次拉新活动最终解释权归翻红馆及京东所有，有任何问题请联系翻红馆客服V信：pick360</p>
        </div>
        <img src="../../assets/close.png" alt="" class="close" @click="changeDetailShow(false)">
      </div>
    </div>
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>
  </div>
</template>
<script>
import { resetRem } from "@/assets/rem.js";
//import  goodsList  from "@/assets/firstReward.js";
export default {
  data() {
    return {
      showToast: "",
      form: this.$form.createForm(this, { name: "form" }),
      detailShow: false,
      active: 0,
      list: [],
      empower: [],
      goods: []
    }
  },
  created() {
    resetRem();
  },
  mounted() {
    this.getEmpower()
  },
  methods: {
    changeDetailShow(canshow) {
      this.detailShow = canshow
    },
    changeTab(active) {
      this.active = active
      if (active == 0) {
        this.getEmpower()
      }
      if (active == 1) {
        this.getGoods()
      }
      if (active == 2) {
        this.getRanking()
      }
    },
    getEmpower() {
      this.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      this.axios.get('/app/systemGroupData/get/DouyinJingdongNew20221011_authorize').then((res) => {
        this.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (this.$code(res)) {
          this.empower = res.data.data
        }
      })

    },
    getGoods() {
      this.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      this.axios.get('/app/systemGroupData/get/DouyinJingdongNew20221011_goods').then((res) => {
        this.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (this.$code(res)) {
          this.goods = res.data.data
        }
      })
    },
    getRanking() {
      var that = this
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios.get("/app/DouyinJingdongNew20221011?activity_id=42 ").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var list = res.data.data;
          that.list = list;
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((err, values) => {
        if (!values.nickname) {
          that.showToast = "请输入抖音昵称";
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
          return false;
        }
        if (!values.account_number) {
          that.showToast = "请输入抖音UID";
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
          return false;
        }
        if (!values.mobile) {
          that.showToast = "请填写手机号获取";
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
          return false;
        } else {
          let reg = /^1\d{10}$/;
          if (!reg.test(values.mobile)) {
            that.showToast = "请填写正确的手机号";
            setTimeout(function () {
              that.showToast = "";
            }, 2000);
            return false;
          }
        }
        var data = {
          account_number: values.account_number,
          nickname: values.nickname,
          mobile:values.mobile
        };
        that.axios.post("/app/DouyinJingdongNew20221011/saveTiktokAccount", data).then((res) => {
          if (res.data.code == 200) {
            that.form.resetFields();
            that.showToast = "提交成功";
            setTimeout(function () {
              that.showToast = "";
            }, 2000);
          }
        });

        // if (!err) {
        //   }
      });
    },
    copy() {
      var that = this;
      var clipboard = new this.Clipboard(".copy");
      clipboard.on("success", () => {
        that.showToast = "链接复制成功";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        clipboard.destroy();
      });
      // 浏览器不支持
      clipboard.on("error", () => {
        that.showToast = "不支持";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        // 释放内存
        clipboard.destroy();
      });
    },
  }
}
</script>
<style scoped>
.firstReward {
  width: 10rem;
  margin: 0 auto;
  /* height: 100vh; */
  min-height: 100vh;
  background: #A954FB url("../../assets/firstReward.png") no-repeat;
  background-size: 100% auto;
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
}

.top {
  height: 6.08rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.3467rem;
  color: #fff;
  font-weight: 500;
}

.top span {
  padding: 0 0.16rem
}

.top .logo_fanhong {
  width: 0.56rem;
  margin-right: 0.1172rem;
}

.top .logo_jd {
  width: 1.5067rem;
}

.top .subTitle {
  margin-top: 0.2933rem;
  font-size: 0.5867rem;
  line-height: 0.8rem;
  color: #FFFFFF;
  font-weight: 700;
}

.top .title {
  font-size: 1.8667rem;
  line-height: 2.5867rem;
  color: #FFE9A9;
  font-weight: 800;
  margin-top: -0.2133rem
}

.top .remark {
  font-size: 0.4267rem;
  color: #FFE9A9;
  font-weight: 700;
  margin-top: -0.1333rem
}

.con {
  width: 9.04rem;
  margin: 0 auto;
  flex: 1;
  overflow: hidden;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}

.content::-webkit-scrollbar {
  width: 0
}

.tab {
  width: 100%;
  position: relative;
  height: 1.3333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab_item {
  height: 1.1733rem;
  line-height: 1.1733rem;
  width: 2.9867rem;
  text-align: center;
  background: #FC486F;
  border-radius: 16px 16px 0px 0px;
  font-size: 0.4267rem;
  color: #FFFFFF;
  font-weight: 800;
}

.active {
  background: #fff;
  color: #FC486F;
}

.tab span {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.16rem;
  background: #fff
}

.index>div {
  margin-top: 0.64rem
}

.index .title {
  text-align: center;
  font-weight: 800;
  margin-bottom: 0.2667rem
}

.index .title h3 {
  font-size: .64rem;
  color: #FFE9A9;
  line-height: 0.5333rem;
  margin-bottom: 0.1867rem;
}

.index .title span {
  font-size: 0.32rem;
  color: #FFFFFF;
  line-height: 0.5333rem;
}

.intro_con {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 0.4267rem 0.8rem;
  line-height: 0.7467rem;
  font-size: 0.3733rem;
}

.intro_con p {
  margin: 0;
  color: #666666;
  font-weight: 400;
}

.intro_con span {
  color: #9A2CFF;
}

.empower_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0.8rem;
}

.empower_con .empower_item {
  width: 2.9333rem;
  height: 3.9733rem;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.16rem
}

.empower_con .other {
  width: 2.9333rem;
  height: 3.9733rem;
}

.empower_item img {
  width: 2.1333rem;
  height: 2.1333rem;
  margin-bottom: 0.2933rem
}

.empower_item a {
  display: block;
  width: 2.4rem;
  height: 0.8533rem;
  line-height: 0.8rem;
  background: #FC486F;
  border-radius: 36px;
  color: #fff;
  font-size: 0.3733rem;
  text-align: center;
  border: 2px solid #FC486F;
  box-sizing: border-box;
}

.empower_item .success {
  color: #979797;
  border-color: #979797;
}

.detail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_con {
  position: relative;
  width: 8.72rem;
  height: 90%;
  padding: 0.8rem;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.28);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.detail_con .title {
  font-size: 0.8rem;
  color: #333333;
  text-align: center;
  font-weight: 800;
  margin-bottom: 0.2667rem;
}

.detail_con .all {
  flex: 1;
  overflow-y: scroll;
}

.detail_con .all::-webkit-scrollbar {
  width: 0
}

.detail_con p {
  margin: 0;
  line-height: 0.7467rem;
  font-size: 0.3733rem;
}

.detail_con .close {
  width: 0.5067rem;
  position: absolute;
  right: 0.4533rem;
  top: 0.4533rem
}

.goods {
  padding-bottom: 1.6rem
}

.goods_item {
  margin-top: 0.32rem;
  padding: 0.3733rem;
  background: #FFF;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.goods_item>span {
  position: absolute;
  right: 0;
  bottom: 0.24rem;
  width: 2.24rem;
  height: 0.7467rem;
  line-height: 0.7467rem;
  text-align: center;
  color: #fff;
  background: #FC496D;
  border-radius: 28px 0px 0px 28px;
  font-size: 0.3733rem;
}

.goods_item>img {
  width: 2.4rem;
  margin-right: 0.32rem
}

.goods_con {
  flex: 1;
}

.goods_title {
  font-size: 0.3733rem;
  line-height: 0.5333rem;
  height: 1.0666rem;
  color: #333333;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0.1867rem;
}

.goods_title span {
  display: inline-block;
  height: 0.4267rem;
  line-height: 0.4267rem;
  width: 0.4267rem;
  margin-right: 0.18rem;
  margin-top: -0.08rem;
  font-size: 0.2933rem;
  background: #333333;
  color: #FFFFFF;
  text-align: center;
  font-weight: 800;
  border-radius: 0.1067rem;
}

.goods_percent {
  width: 2.6667rem;
  height: 0.5333rem;
  border: 2px solid rgba(255, 84, 136, 1);
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 0.4533rem;
  text-align: center;
  color: #FF5488;
  margin-bottom: 0.24rem;
  font-size: 0.32rem;
}

.goods_price {
  font-size: 0.2667rem;
  color: #999999;
}

.goods_price b {
  color: #333333;
  font-weight: 500;
}

.goods_price span {
  text-decoration: line-through;
}

.ranking {
  padding-bottom: 1.6rem
}

.ranking_item {
  margin-top: 0.32rem;
  padding: 0.4rem 0.48rem 0.4rem 0.32rem;
  background: #FFF;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #FFFFFF;
}

.ranking_item:first-child {
  background: #FFE9A9;
}

.ranking_item:nth-child(2) {
  background: #FFF1C9;
}

.ranking_item:nth-child(3) {
  background: #FFFAE9;
}

.ranking_account {
  font-size: 0.4267rem;
  color: #FC486F;
  font-weight: 700;
}

.ranking_con {
  flex: 1;
  font-size: 0.3733rem;
  color: #999;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.ranking_con img {
  margin: 0 0.16rem;
  width: 1.0667rem;
  border-radius: 50%;
}

.ranking_con p {
  margin: 0;
  font-size: 0.32rem;
  line-height: 0.4533rem;
}

.ranking_con p span {
  color: #000;
  line-height: 0.5263rem;
  font-size: 0.3733rem;
  padding-bottom: 0.0533rem
}

.ranking_item:first-child .ranking_con>span,
.ranking_item:nth-child(2) .ranking_con>span,
.ranking_item:nth-child(3) .ranking_con>span {
  color: #000;
}

.showToast {
  position: fixed;
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 0;
}

.showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  letter-spacing: 0;
}

.form {
  background: #fff;
  border-radius: 16px;
  padding: 0.6267rem 0.8rem;
}

.ant-form-item {
  margin-bottom: 0.36rem
}

/deep/.ant-input {

  height: 0.8533rem;
}

.button {
  text-align: center;
}

.ant-btn {
  width: 2.4rem;
  background: #FC486F;
  border-color: #FC486F;
  color: #fff;
  font-size: 0.3733rem;
  height: 0.8533rem;
  line-height: 0.8rem;
  margin: 0 auto;
}
</style>